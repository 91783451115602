input {
  -webkit-appearance: none;
}

.input {
  display: flex;
  position: relative;
  width: calc(100% - 2px);
  height: 40px;
  margin: 0 1px 0px 1px;
  padding: 8px 16px;

  background-color: var(--white);
  border-width: 1px;
  border-style: solid;
  border-color: var(--black-300);
  border-radius: 2px;
  outline: none;
  overflow: hidden;

  text-align: left;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  &::placeholder {
    color: var(--black-600);
    /* because of firefox can't use font.data */
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
  }

  &:focus-within {
    border-color: var(--blue-300);
    box-shadow: 0 0 0 1px var(--blue-300);
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}
