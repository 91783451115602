@import "../../style/default";
@import "../../style/notification";
@import "../../style/button";
@import "../../style/input.scss";
@import "../../style/link.scss";

$min-heigh: 750px;

.email-container {
  padding: 120px 30px;
}

.content {
  color: var(--black-900, #16293F);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.heading {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  line-height: 28px;
  margin-top: -55px;
}

.user-email {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.please-click {
  margin-top: 24px;
  margin-bottom: 24px;
}


.fulllogo {
  margin: 0 auto;
}


.email-sent {
  margin-top: 50px;
}

.copyright {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;

  &__text {
    font-size: 11px;
    line-height: 16px;
    color: var(--black-600);
  }
}
