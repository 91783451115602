@import "../../style/default";
@import "../../style/notification";
@import "../../style/button";
@import "../../style/input.scss";
@import "../../style/link.scss";

$min-heigh: 750px;

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  padding: 32px 32px 0px 32px;

  @include for-size(tablet-portrait-up) {
    padding: 48px 48px 32px 48px;
  }

  @media (min-width: 600px) {
    padding: 48px 48px 32px 48px;
  }
}

.header {
  @include for-size(desktop-up) {
    @media (min-height: $min-heigh) {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.footer {
  padding-top: 48px;
  max-width: $form-width;
  width: 100%;
  margin: 0 auto;

  @include for-size(desktop-up) {
    @media (min-height: $min-heigh) {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      max-width: 100%;
    }
  }
}

.copyright {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;

  &__text {
    font-size: 11px;
    line-height: 16px;
    color: var(--black-600);
  }
}

.form__subtitle {
  font-size: 12px;
}

.password__wrapper {
  position: relative;
}

.password__check-container {
  display: flex;
  justify-content: space-around;
  position: absolute;
  flex-direction: column;
  align-items: center;
}

.password__check-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 10;
  background-color: #16293f;
  padding: 12px;
  border-radius: 4px;
  color: #fff;
}

.password__check-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.password__check-condition-wrapper {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
