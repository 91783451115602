@import "animation";

.button {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: space-evenly;

  height: 40px;

  border-style: solid;
  border-color: transparent;
  border-image: initial;
  border-width: 1px;
  border-radius: 2px;

  padding: 0 16px;

  color: rgb(255, 255, 255);
  font: 500 14px Roboto, sans-serif;
  letter-spacing: 0.15px;
  line-height: 20px;
  text-decoration: none;

  cursor: pointer;
  transition-property: background, opacity, box-shadow;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.02, 1.11, 0.38, 0.97);

  user-select: none;
  white-space: nowrap;
  z-index: 1;
  outline: none;

  &--main {
    background-color: var(--blue-500);

    &:hover {
      background-color: var(--blue-600);
    }

    &:active {
      background-color: var(--blue-700);
    }
  }

  &--secondary {
    background-image: linear-gradient(
      180deg,
      var(--black-50) 0%,
      var(--black-100) 100%
    );
    border-color: var(--black-400);
    color: var(--black-700);

    &:hover {
      background-image: linear-gradient(
        180deg,
        var(--black-100) 0%,
        var(--black-200) 100%
      );
    }

    &:active {
      color: var(--black-800);
      background-color: var(--black-300);
      background-image: initial;
      border-color: transparent;
    }

    &-transparent {
      border-color: transparent;
      background-image: initial;
      background-color: transparent;

      &:hover {
        background-image: initial;
        background-color: rgba(22, 41, 63, 0.04);
      }

      &:active {
        background-image: initial;
        background-color: rgba(22, 41, 63, 0.12);
      }
    }
  }

  &--loading {
    color: transparent;
    cursor: initial;

    &::before {
      content: "";

      position: absolute;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border-width: 2px;
      border-style: solid;
      border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(255, 255, 255)
        transparent;
      border-image: initial;
      animation: 0.6s linear 0s infinite normal none running rotate;
    }
  }

  &:disabled {
    border-color: transparent;
    background-image: initial;
    background-color: var(--black-300);
    color: var(--black-500);
    cursor: default;
  }
}
