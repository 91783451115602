a:not(.button) {
  text-decoration: none;
  color: var(--blue-500);

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    color: var(--blue-700);
  }
}
