$notification-padding: 16px;
$notification-icon-size: 24px;

.notification {
  position: relative;
  display: none;

  width: 100%;
  max-height: 120px;
  margin-bottom: 24px;
  padding: $notification-padding;
  align-items: flex-start;
  justify-content: flex-start;

  color: var(--black-900);
  font-size: 14px;

  z-index: 9999;

  &--show {
    display: flex;
  }

  &--error {
    background-color: var(--red-100);

    .notification__icon {
      background-image: url("../icons/notification/error.svg");
    }
  }

  &--warning {
    background-color: var(--orange-100);

    .notification__icon {
      background-image: url("../icons/notification/warning.svg");
    }
  }

  &--success {
    background-color: var(--green-100);

    .notification__icon {
      background-image: url("../icons/notification/success.svg");
    }
  }

  &--info {
    background-color: var(--blue-100);

    .notification__icon {
      background-image: url("../icons/notification/info.svg");
    }
  }

  &__icon {
    width: $notification-icon-size;
    height: $notification-icon-size;
    flex-shrink: 0;
    margin-right: 8px;

    background-repeat: no-repeat;
    background-position: center;
  }

  &__message {
    display: flex;
    flex-direction: column;
    padding-top: 2px;

    &-title {
      font-weight: 500;
    }

    &-body {
      margin: 0;

      &:not(:empty) {
        margin-top: 8px;
      }
    }
  }
}
