:root {
  --primary-color: #2354c3;
  --blue-300: #83b2ee;
  --blue-500: #265eca;
  --blue-600: #2354c3;
  --blue-700: #1d3a93;

  --white: #ffffff;

  --black-50: #fcfcfd;
  --black-100: #f6f7f8;
  --black-200: #edeef0;
  --black-300: #e0e2e6;
  --black-400: #c7ccd1;
  --black-500: #a9b0b8;
  --black-600: #858f9b;
  --black-700: #626f7d;
  --black-800: #405062;
  --black-900: #16293f;

  --blue-100: #dae7f8;
  --red-100: #ffcdd2;
  --orange-100: #fff3d9;
  --green-100: #c8ffce;
}

$form-width: 280px;

@mixin for-size($range) {
  $phone-upper-boundary: 600px;
  $tablet-portrait-upper-boundary: 900px;
  $tablet-landscape-upper-boundary: 1200px;
  $desktop-upper-boundary: 1800px;

  @if $range == phone-only {
    @media (max-width: #{$phone-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == tablet-portrait-up {
    @media (min-width: $phone-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-landscape-up {
    @media (min-width: $tablet-portrait-upper-boundary) {
      @content;
    }
  } @else if $range == desktop-up {
    @media (min-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  } @else if $range == big-desktop-up {
    @media (min-width: $desktop-upper-boundary) {
      @content;
    }
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  min-height: 100%;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  min-height: 100%;
  margin: 0;
  background-color: var(--black-100);

  font-size: 14px;
  color: var(--black-900);
  line-height: 20px;
  font-weight: 400;
}

.container {
  width: 100%;
  min-height: 100vh;
}

.header {
  margin: 0 auto 40px auto;
  max-width: $form-width;
  width: 100%;
  height: 46px;
}

.main {
  max-width: $form-width;
  width: 100%;
  margin: 0 auto;
}

h1 {
  margin: 0;
  margin-bottom: 40px;
  color: var(--black-900);
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
}

.form-wrapper {
  position: relative;
  z-index: 1;
  br {
    display: none;

    @include for-size(tablet-portrait-up) {
      display: block;
    }
  }

  @include for-size(tablet-portrait-up) {
    width: 352px;
  }
}

.form {
  max-width: $form-width;

  &__title {
    margin-bottom: 32px;
  }

  &__subtitle {
    margin-bottom: 24px;
  }

  &__fields {
    width: 100%;
  }

  &__field {
    width: 100%;
    margin-bottom: 24px;
    color: var(--black-700);

    label .input {
      margin-top: 8px;
    }
  }

  &__buttons {
    display: flex;
    margin-bottom: 24px;

    & * + * {
      margin-left: 16px;
    }
  }

  &__social-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 24px 0;

    &-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 10px 0px;
  
      & button {
        width: 100%;
      }
    }
  }

  &__no-account-caption {
    margin-top: 24px;
    & .link {
      margin-left: 5px;
    }
  }
}
