@import "../../style/default";
@import "../../style/notification";
@import "../../style/button";
@import "../../style/input.scss";
@import "../../style/link.scss";

.container {
  padding: 32px 32px 0px 32px;

  @include for-size(tablet-portrait-up) {
    padding: 48px 48px 32px 48px;
  }

  @media (min-width: 600px) {
    padding: 48px 48px 32px 48px;
  }
}

.form-wrapper {
  &--hidden {
    display: none;
  }
}

.form {
  &__message {
    margin-bottom: 32px;

    text-align: left;
    color: var(--black-900);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
  }
}
